
const mobileAgents = ["Android", "iPhone", "iPad", "iPod", "Symbian"];
let userAgent = navigator.userAgent;

export const Common = {
	isMobile: mobileAgents.some(ag => userAgent.includes(ag)),
	gitbook() {
		window.open('https://aidemi.gitbook.io/aidemi', '_block');
	},
	deck(){
		window.open('https://docsend.com/view/8naz92z2tgweasi7', '_block');
	},
	mirror(){
		window.open('https://mirror.xyz/0x2c347A9392EB7204ad5aC727f9A14fb4D55c63BE', '_block');
	},
	toTwitter() {
		window.open('https://x.com/AIDemi_Official', '_block');
	},
	onepage(){
		window.open('https://drive.google.com/file/d/1ychf3IytTy9kg2mYHOxCTIEDY_lNRlYQ/view?usp=drive_link', '_block');
	},
	closeNavigationTab() {

	}

}
