import './Foot.scss'
import { Common } from '../../app/Common'
import { useEffect, useState } from 'react'

export function Foot() {
  // @ts-ignore
  const Web = ( <div id="Foot" className='w-100 ' mode="web">
        {/* <div>
            <img className="w-r-146 h-r-95" src={require('../../assets/web/Foot/dc.png')} alt=""  />
            <div className='f-14 mt-20'>© 2024 DSC, All Rights Reserved.</div>
        </div>
        <div className='flexcc'>
          <img className="w-r-63 h-r-63 ml-20" src={require('../../assets/web/Foot/1.png')} alt=""  />
          <img className="w-r-63 h-r-63 ml-20" src={require('../../assets/web/Foot/2.png')} alt=""  />
          <img className="w-r-63 h-r-63 ml-20" src={require('../../assets/web/Foot/3.png')} alt=""  />
          <img className="w-r-63 h-r-63 ml-20" src={require('../../assets/web/Foot/4.png')} alt=""  />
          <img className="w-r-63 h-r-63 ml-20" src={require('../../assets/web/Foot/5.png')} alt=""  />
        </div> */}
    </div>
  )
  // @ts-ignore
  const Mobile = ( <div id="Foot" mode="mobile">
    </div>
  )
  return Common.isMobile ? Mobile : Web
}
