import "./Navigation.scss";
import { Common } from "../../app/Common";
import { useEffect, useState } from 'react'
import { Button, Radio ,Dropdown,Menu} from 'antd';
import { CloseOutline } from 'antd-mobile-icons'
import { To, useNavigate,useLocation } from "react-router-dom";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount,useDisconnect } from 'wagmi'
  
export function Navigation() {
     const { open, close } = useWeb3Modal();
     const { address, isConnecting, isDisconnected } = useAccount();
    const navigate = useNavigate();
    const skip = (url: To,type: string) => {
      if(type){
        navigate(url, { state: { type:type } })
      }else {
        navigate(url)
      }
    }
  const { disconnect } = useDisconnect();

  function formtterAddress(address:any){
      if(address){
        return address.substring(0,5)+'...'+address.substring(address.length-4,address.length);
      }
      return '';
  }

  function  logo(){
    return <div  className="flexcc cur" onClick={() => skip('/',"")}>
          <img className="w-r-166 h-r-87" src={require('../../assets/web/logo.png')} alt=""  />
    </div>
}

const NPCmenu = (
  <Menu
    items={[
      {
        key: '4',
        label: 'Guide NPC',
      },
      {
        key: '3',
        label: 'Native Companion NPC',
      },
    ]}
  />
);
const Modelmenu = (
  <Menu
    items={[
      {
        key: '4',
        label: 'Purchase',
      },
      {
        key: '3',
        label: 'Train',
      },
      {
        key: '2',
        label: 'Trade',
      },
    ]}
  />
);
const Aboutmenu = (
  <Menu
    items={[
      {
        key: '4',
        label: 'About Us',
      },
      {
        key: '3',
        label: 'Roadmap',
      },
      {
        key: '2',
        label: 'Partners',
      },
    ]}
  />
);


const Docmenu = (
  <Menu
    items={[
      {
        key: '1',
        label: <span onClick={()=>{Common.gitbook()}} >White paper</span>,
      },
      {
        key: '2',
        label: <span onClick={()=>{Common.deck()}} >Deck</span>,
      },
      {
        key: '3',
        label:  <span onClick={()=>{Common.onepage()}} >Onepage</span>,
      },
    ]}
  />
);



  // @ts-ignore
  const Web = <div id="navigation"  mode="web">
      {/* 占位 */}
      <div className="navigationZ"></div>
      <div className="navigation">
          <div>{logo()}</div>
         <div className="flexcc navList h-100 text-black f-19 f-w-7">
            <div className="ml-100 navItem cur" onClick={() => skip('/',"")}>Your Companion</div>
           
            <Dropdown  
              overlayClassName="dropdown"
              getPopupContainer={() => document.getElementById('navigation')}
              overlay={NPCmenu} placement="bottomRight" >
              <div className="ml-100 navItem cur" >NPC</div>
            </Dropdown>
        
          
            <Dropdown  
              overlayClassName="dropdown"
              getPopupContainer={() => document.getElementById('navigation')}
              overlay={Modelmenu} placement="bottomRight" >
              <div className="ml-100 navItem cur">AI Model</div>
            </Dropdown>
            <Dropdown  
              overlayClassName="dropdown"
              getPopupContainer={() => document.getElementById('navigation')}
              overlay={Aboutmenu} placement="bottomRight" >
              <div className="ml-100 navItem cur">About</div>
            </Dropdown>

            <Dropdown  
              overlayClassName="dropdown"
              getPopupContainer={() => document.getElementById('navigation')}
              overlay={Docmenu} placement="bottomRight"
            
              >
              <div className="ml-100 navItem cur">Doc</div>
            </Dropdown>
           
            {address ? 
                <div className="ml-100 web3ButOk flexcc cur" >
                  <div className="w-r-12 h-r-12 br-12 bg-white mr-20"></div>
                  {formtterAddress(address)}</div>
            : <div className="ml-100  flexcc web3But cur" onClick={() => open()}>
              <div className="w-r-12 h-r-12 br-12 bg-black mr-20"></div>
               Connect Wallet</div>}
           
        </div>
      </div>
  </div>


const [mentShow,setMentShow]=useState(false);
const switchMenu = () => {
  setMentShow(!mentShow);
}
  // @ts-ignore
  const Mobile = <div id="navigation" mode="mobile">
    <div className="navigationZ"></div>
    <div className="navigation px-7 py-3 flexsb">
      <img className="w-r-71 h-r-38" src={require(`@/assets/mobile/logo.png`)} alt=""  />
      <div className="flexc">
      {address ? <div className="w-r-112 h-r-21 br-24 flexcc f-10 text-black" style={{border: "1px solid #000000",background:'#71d3ff'}}>
           <div className="w-r-4 h-r-4 br-4 bg-white mr-4"></div> {formtterAddress(address)}
        </div>
      : <div className="w-r-112 h-r-21 br-24 flexcc f-10 text-black" style={{border: "1px solid #000000"}}>
          <div className="w-r-4 h-r-4 br-4 bg-black mr-4"></div>Connect the wallet
        </div>}

        <img onClick={switchMenu} className="w-r-21 h-r-21 ml-20" src={require(`@/assets/mobile/Home/men.png`)} alt=""  />
      </div>
    </div>

    {
      mentShow?<div className="mentList px-10">
          <div className="mentListItem">
            Your Companion
          </div>
          <div className="mentListItem">
            <div className="lh-25">NPC</div>
            <div className="lh-25 f-w-4">Guide NPC</div>
            <div className="lh-25 f-w-4">Native Companion NPC</div>
          </div>
          <div className="mentListItem">
            <div className="lh-25">AI Model</div>
            <div className="lh-25 f-w-4">Purchase</div>
            <div className="lh-25 f-w-4">Train</div>
            <div className="lh-25 f-w-4">Trade</div>
          </div>
          <div className="mentListItem">
            <div className="lh-25">About</div>
            <div className="lh-25 f-w-4">About Us</div>
            <div className="lh-25 f-w-4">Roadmap</div>
            <div className="lh-25 f-w-4">Partners</div>
          </div>
          <div className="mentListItem">
              Doc
          </div>
      </div>:""
    }
    

  </div>
  return Common.isMobile ? Mobile : Web
}
