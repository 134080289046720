/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './index.scss'
import { Common } from '@/app/Common'
import { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useLocation, To } from 'react-router-dom'
import { httpGet, httpPost } from '../../../utils/httpUtils'
import { Carousel,Input } from 'antd';
import { SearchOutline } from 'antd-mobile-icons'
import {  Swiper } from 'antd-mobile'
import {
  SearchOutlined 
} from '@ant-design/icons';
export function HomePage() {
  const navigate = useNavigate()
  const skip = (url: To) => {
    navigate(url)
  }


  // ai
  let nameList = [
    {name:'Evelyn',ai:1},
    {name:'Mizuki Sakurai',ai:2},
    {name:'Lim',ai:3},
    {name:'Chander',ai:4},
    {name:'Joon-ho',ai:5},
    {name:'Feng Lin',ai:6},
  ]

  const [ai,setAi] = useState(1)
  const selAi=(ai:number)=>{
    setAi(ai)
  }


  // chatList
  let chatList = [
    {name:'Lillian ',chat:1},
    {name:'Amelia',chat:2},
    {name:'Raymond',chat:3},
  ]
  const [chat,setChat] = useState(0)
  const selChat = (chatId:number)=>{
    setChat(chatId)
  }
  

  // @ts-ignore
  const Web = (<div id="HomePage" mode="web" className='position-relative'>
      <div className='homeBg'>
        <div className='w-100  pt-60'>
          <img className="w-100 h-r-1043" style={{marginLeft:'-50px'}} src={require('@/assets/web/Home/homeH1.png')} alt=""  />
        </div>
        <div className='flexcc py-30 f-22 f-w-5'>
          <div className='but flexcc mx-80 cur'>AIDemi AI Model Claim</div>
          <div className='but flexcc mx-80 cur'>AI Companion Chat</div>
        </div>
        <div className="w-100 h-r-610 flexcc mt-40">
          <div className='h-r-610 w-r-1170  bgList'>
            <img className=" bgItem1  cur" src={require('@/assets/web/Home/Group2.png')} alt=""  /> 
            <img className="  bgItem2 cur" src={require('@/assets/web/Home/Group3.png')} alt=""  /> 
            <img className="  bgItem3 cur" src={require('@/assets/web/Home/Group1.png')} alt=""  /> 
            <img className="  bgItem4 cur" src={require('@/assets/web/Home/Group4.png')} alt=""  /> 
            <img className="  bgItem5 cur" src={require('@/assets/web/Home/Group5.png')} alt=""  /> 
          </div>
        </div>
      </div>

      <div className="enable-animation">
        <div className="marquee">
          <ul className="marquee__content">
            <div className="marquee__item">
            <img className="w-100 h-r-336" src={require('@/assets/web/Home/1.png')} alt=""  />
            </div>
          </ul>
          <ul aria-hidden="true" className="marquee__content">
          <div className="marquee__item">
          <img className="w-100 h-r-336" src={require('@/assets/web/Home/1.png')} alt=""  />
            </div>
          </ul>
        </div>
      </div>

      {/* Functionality */}
      <div>
        <div className='w-100 flexcc mt-40 mb-20'>
          <img className="w-r-441 h-r-80" src={require('@/assets/web/Home/fun.png')} alt=""  />
        </div>
        <div className='w-100 flexcc'>
          <img className="w-r-542 h-r-461" src={require('@/assets/web/Home/Group60.png')} alt=""  />
          <img className="my-20 w-r-542 h-r-461" src={require('@/assets/web/Home/Group61.png')} alt=""  />
          <img className="w-r-542 h-r-461" src={require('@/assets/web/Home/Group62.png')} alt=""  />
        </div>
      </div>

      {/* Aurora */}
      <div className='AuroraBox'>
        <div className='w-r-860 px-30 py-40 Aurora'>
        <span className='f-w-7'>Aurora</span> is equipped with complex <br/>
        emotional simulation algorithms and <br/>
        deep learning capabilities, capable of <br/>
        understanding your personalised needs.<br/>
        </div>

        <div className='flexc w-r-860 p-30'>
              <div className='w-r-336 h-r-75 br-70 flexcc f-18 f-w-5 cur' style={{border:"1px solid #2F2F30"}}>Chat with Aurora</div>
              <div className='f-22 f-w-5 ml-20'>Learn everything about AIDemi</div>
        </div>
      </div>

      {/* ai */}
      <div className='flexcc pl-120 mt-80 mb-40'>
        <div className='w-r-213 f-18'>
          {
            nameList.map((item,index)=>{
              return <div  key={index}  className={`w-r-202 h-r-72 flexc pl-20 cur ${ai===item.ai ? 'aiBg' : ''}`}  onClick={() => selAi(item.ai)}>
                  {item.name}
              </div>
            })
          }
        </div>
        <div className='w-r-1472 h-r-832' style={{marginLeft:"-20px"}}>
            <img className="w-100 h-100" src={require(`@/assets/web/Home/ai${ai}.png`)} alt=""  />
        </div>

      </div>


      {/* Purchase */}
      <div>
          <div className='flexd align-items-center'>
                <div className='text-center f-42 f-w-5 my-30'>
                Start with AIDemi native model Tom,<br></br> train your own “him/her”.
                </div>
                <div className=' w-r-336 h-r-75 flexcc f-18 f-w-5 br-70 cur' style={{border:'1px solid #2F2F30'}}>
                Purchase
                </div>
            </div>

            {/* <div className='flexcc overflow-hidden my-50'>
            <img className="w-r-3662 h-r-526" src={require('@/assets/web/Home/Group88.png')} alt=""  />
            </div> */}
          <div className="enable-animation mt-80 mb-40">
          <div className="marquee">
            <ul className="marquee__content" >
              <div className="marquee__item px-50">
              <img className="w-100 h-r-526" src={require('@/assets/web/Home/Group78.png')} alt=""  />
              </div>
            </ul>
            <ul aria-hidden="true" className="marquee__content ">
            <div className="marquee__item px-50">
            <img className="w-100 h-r-526" src={require('@/assets/web/Home/Group78.png')} alt=""  />
              </div>
            </ul>
          </div>
        </div>
      </div>


       {/* Start training */}
       <div>
          <div className='flexd align-items-center'>
              <div className='text-center f-42 f-w-5 my-30'>
                Use data to customise your AIdemi
              </div>
              <div className='cur w-r-336 h-r-75 flexcc f-18 f-w-5 br-70' style={{border:'1px solid #2F2F30'}}>
              Start training
              </div>
          </div>
          <div className='flexcc mt-40 mb-20'>
            <img className="w-r-1681 h-r-864" src={require('@/assets/web/Home/Frame5417.png')} alt=""  />
          </div>
      </div>


      {/* Trade your AI model */}
      <div className='flexsb px-60 mt-80'>
        <div className='h-r-300'>
            <div className='f-42 f-w-5'>Trade your AI model</div>
            <div className='f-19 w-r-336 h-r-75 flexcc br-70 mt-50 cur' style={{border:'1px solid #2F2F30'}}>Marketplace</div>
        </div>
        <div className='flexcc mt-40 mb-20'>
            <img className="w-r-1280 h-r-660" src={require('@/assets/web/Home/Frame5418.png')} alt=""  />
          </div>
      </div>

        {/* Your Companion */}
      <div className=' my-80 flexd align-items-center'>
        <img className="w-r-450 h-r-50" src={require('@/assets/web/Home/your.png')} alt=""  />
        <div className='f-43 f-w-5 my-40'>Enjoy your AI companion time</div>
        <div className='f-19 w-r-336 h-r-75 flexcc br-70 cur' style={{border:'1px solid #2F2F30'}}>Start chatting</div>

        <div className='w-r-1425 h-r-630 br-13 mt-50 flexsb' style={{border: "2.67px solid #000000"}}>
            <div className='w-r-281 h-100 px-20 py-30' style={{borderRight: "2.67px solid #000000"}}>
              <div className='f-30 f-w-5'>Chat</div>
              <div className='h-r-34 w-100 my-20 br-13 overflow-hidden' style={{border: "1.33px solid #000000"}}><Input size="large" placeholder="" prefix={<SearchOutlined />} /></div>
              {
                chatList.map((item,index)=>{
                  return  <div key={index}  className={`w-100 h-r-84 flexc cur ${chat===item.chat ? 'bg-d9' : ''}`}   onClick={() => selChat(item.chat)} style={{borderBottom: "2.67px solid #000000"}}>
                    <img className="w-r-69 h-r-69 mr-50" src={require(`@/assets/web/Home/chat${item.chat}.png`)} alt=""  />
                    <div className='f-16 f-w-5'>{item.name}</div>
                  </div>
                })
              }
            </div>
            {
              chat!=0? <div className='h-100 w-r-516' style={{borderRight: "2.67px solid #000000"}}>
                    <img className="w-100 h-100" src={require('@/assets/web/Home/Lillian.png')} alt=""  />
              </div>:""
            }
            <div className='flex-fill h-100 p-20 flexd'>
            {
              chat!=0? <div className='flex-fill'>
                <div className='flexc'>
                  <img className="w-r-33 h-r-33 mr-10" src={require(`@/assets/web/Home/chat1.png`)} alt=""  />
                  <div className='msg f-16'>Good night, my friend😊</div>
                </div>
              </div>: <div className='flex-fill  flexcc f-18'>Select Chat to start the conversation</div>
            }
               
            <div className='h-r-65  w-100 flexcc'>
              <div className='h-r-57 w-r-421 my-20 br-22 overflow-hidden' style={{border: "1px solid #000000"}}><Input size="large" className='w-100 h-100' placeholder="Enter text"  /></div>
              <img className="w-r-65 h-r-65 ml-20" src={require('@/assets/web/Home/mic.png')} alt=""  />
            </div>
            </div>
        </div>
      </div>


      {/* Features */}
      <div className=' flexd align-items-center'>
        <div className='f-65 f-w-5 my-20'>Features</div>
        <div className='px-30 flexsb'>
          <img className="w-r-454 h-r-276" src={require('@/assets/web/Home/div1.png')} alt=""  />
          <img className="w-r-454 h-r-276" src={require('@/assets/web/Home/div2.png')} alt=""  />
          <img className="w-r-454 h-r-276" src={require('@/assets/web/Home/div3.png')} alt=""  />
          <img className="w-r-454 h-r-276" src={require('@/assets/web/Home/div4.png')} alt=""  />
        </div>
      </div>


     <div className='footBg'>
       {/* Roadmap */}
      <div className=' flexd align-items-center'>
        <div className='f-65 f-w-5 mt-40 mb-20'>Roadmap</div>
        <div className=' flexcc'>
          <img className="w-100 h-r-666" src={require('@/assets/web/Home/roadmap.png')} alt=""  />
        </div>
      </div>

      {/* Partners */}
      {/* <div className=' flexd align-items-center justify-content-center my-50'>
        <div className='f-65 f-w-5 mt-40 mb-20'>Partners</div>
         <div className="enable-animation py-30">
          <div className="marquee">
            <ul className="marquee__content" >
              <div className="marquee__item px-50">
              <img className="w-147 h-r-40" src={require('@/assets/web/Home/par.png')} alt=""  />
              </div>
            </ul>
            <ul aria-hidden="true" className="marquee__content ">
            <div className="marquee__item px-50">
                <img className="w-147 h-r-40" src={require('@/assets/web/Home/par.png')} alt=""  />
            </div>
            </ul>
          </div>
        </div>
      </div> */}

        {/* foot */}
      <div className='flexsb px-118 py-30'>
        <img className="w-r-267 h-r-109" src={require('@/assets/web/logo.png')} alt=""  />
        <div className='flexcc'>
          <img className="w-r-58 h-r-58 mx-10" onClick={()=>{ Common.toTwitter();  }} src={require('@/assets/web/Home/f1.png')} alt=""  />
          <img className="w-r-58 h-r-58 mx-10"  src={require('@/assets/web/Home/f2.png')} alt=""  />
          <img className="w-r-58 h-r-58 mx-10"  onClick={()=>{ Common.mirror();  }} src={require('@/assets/web/Home/f3.png')} alt=""  />
          <img className="w-r-58 h-r-58 mx-10"  onClick={()=>{ Common.deck();  }} src={require('@/assets/web/Home/f4.png')} alt=""  />
          <img className="w-r-58 h-r-58 mx-10" onClick={()=>{ Common.onepage();  }}  src={require('@/assets/web/Home/f5.png')} alt=""  />
        </div>
      </div>
     </div>
            
  </div>
  )
  // @ts-ignore
  const Mobile = <div id="HomePage" mode="mobile">
    <div className='HomePageT'>
          <div className='overflow-hidden w-100 flexcc py-30'>
            <img className=" w-r-480 " src={require('@/assets/mobile/Home/homeTop1.png')} alt=""  /> 
          </div>
          <div className='flexcc'>
            <img className=" w-r-145 h-r-30 mr-10" src={require('@/assets/mobile/Home/but2.png')} alt=""  /> 
            <img className=" w-r-145 h-r-30 " src={require('@/assets/mobile/Home/but1.png')} alt=""  /> 
          </div>
          <div className='w-100 h-r-300'>
          <Swiper  className="root" slideSize={38} trackOffset={30} loop indicator={() => null} stuckAtBoundary={false}>
            <Swiper.Item>
              <div className='flexcc h-r-300'>
                  <img className=" w-r-127 h-r-262 " src={require('@/assets/mobile/Home/w1.png')} alt=""  /> 
              </div>
            </Swiper.Item>
            <Swiper.Item>
              <div className='flexcc h-r-300'>
                <img className=" w-r-140 h-r-262 " src={require('@/assets/mobile/Home/w2.png')} alt=""  />
              </div> 
            </Swiper.Item>
            <Swiper.Item>
                <div className='flexcc h-r-300'>
                  <img className=" w-r-127 h-r-262 " src={require('@/assets/mobile/Home/w3.png')} alt=""  />
                </div> 
            </Swiper.Item>
            <Swiper.Item>
                <div className='flexcc h-r-300'>
                  <img className=" w-r-127 h-r-262 " src={require('@/assets/mobile/Home/w4.png')} alt=""  />
                </div>  
            </Swiper.Item>
            <Swiper.Item>
                <div className='flexcc h-r-300'>
                  <img className=" w-r-127 h-r-262 " src={require('@/assets/mobile/Home/w5.png')} alt=""  />
                </div>  
            </Swiper.Item>
          </Swiper>
          </div>
    </div>
    {/* AI */}
    <div className='w-100 h-r-164 d-flex overflow-x-auto'>
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a1.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a2.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a3.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a4.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a5.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a6.png')} alt=""  /> 
      <img className=" w-r-124 h-r-164 " src={require('@/assets/mobile/Home/a7.png')} alt=""  /> 
    </div>

    {/* fun */}
    <div>
      <img className=" w-r-165 h-r-25  mt-20 mx-10 mb-10" src={require('@/assets/mobile/Home/Functionality.png')} alt=""  /> 
      <div className='w-100 h-r-170 d-flex overflow-x-auto'>
        <img className=" w-r-200 h-r-170 " src={require('@/assets/mobile/Home/fun2.png')} alt=""  /> 
        <img className=" w-r-200 h-r-170 " src={require('@/assets/mobile/Home/fun1.png')} alt=""  /> 
        <img className=" w-r-200 h-r-170" src={require('@/assets/mobile/Home/fun3.png')} alt=""  /> 
      </div>
    </div>

    <div className='px-30'>
      <div className='flexcc mt-20'>
          <img className=" w-r-116 h-r-174" src={require('@/assets/mobile/Home/fun4.png')} alt=""  /> 
      </div>
      <div className=' text-black py-15 px-10 f-w-5 f-14' style={{borderBottom: "1px solid #2F2F30",borderTop: "1px solid #2F2F30"}}>
        <span className='f-w-7'>Aurora</span> is equipped with complex <br/>
        emotional simulation algorithms and <br/>
        deep learning capabilities, capable of <br/>
        understanding your personalised needs.<br/>
      </div>
      <div className='flexcc my-20'>
        <div className='flexcc w-r-137 h-r-30 br-28 f-12' style={{border: "0.54px solid #2F2F30"}}>Chat with Aurora</div>
        <div className='w-r-95 f-10 ml-20'>
        Learn everything about AIDemi
        </div>
      </div>
    </div>

          {/* ai */}
    <div>
      <div className='flexcc mb-8'>
        {
          nameList.map((item,index)=>{
            return <div  key={index}  className={`f-10 text-gray px-8 py-4  ${ai===item.ai ? 'aiBg' : ''}`}  onClick={() => selAi(item.ai)}>
                {item.name}
            </div>
          })
        }
      </div>
      <div className='w-100 h-r-211' >
          <img className="w-100 h-100" src={require(`@/assets/mobile/Home/ai${ai}.png`)} alt=""  />
      </div>
    </div>

   {/* Purchase */}
   <div className='flexd align-items-center mt-40'>
        <div className='w-r-293 text-center f-16 f-w-7 text-black'>
          Start with AIDemi native model Tom,<br/> train your own “him/her”.
        </div>
        <div className='flexcc w-r-135 h-r-30 br-28 f-12 my-20' style={{border: "0.54px solid #2F2F30"}}>Purchase</div>
        <div className='w-100 h-r-183 overflow-hidden'>
          <img className="w-154 h-100" src={require(`@/assets/mobile/Home/Purchase.png`)} alt=""  />
        </div>
   </div>
    
      {/* Start training */}
   <div className='flexd align-items-center mt-40'>
        <div className='w-r-293 text-center f-16 f-w-7 text-black'>
         Use data to customise your AIdemi
        </div>
        <div className='flexcc w-r-135 h-r-30 br-28 f-12 mb-10 mt-20' style={{border: "0.54px solid #2F2F30"}}>Start training</div>
        <div className='w-r-342 h-r-218 overflow-hidden'>
          <img className="w-100 h-100" src={require(`@/assets/mobile/Home/Start.png`)} alt=""  />
        </div>
   </div>

     {/* Marketplace */}
    <div className='flexd align-items-center mt-40'>
      <div className='w-r-293 text-center f-16 f-w-7 text-black'>
      Trade your AI model
      </div>
      <div className='flexcc w-r-135 h-r-30 br-28 f-12 mb-10 mt-20' style={{border: "0.54px solid #2F2F30"}}>Marketplace</div>
      <div className='w-r-342 h-r-190 overflow-hidden'>
        <img className="w-100 h-100" src={require(`@/assets/mobile/Home/Marketplace.png`)} alt=""  />
      </div>
    </div>

     {/* Start chatting */}
     <div className='flexd align-items-center mt-40'>
      <img className="w-r-211" src={require(`@/assets/mobile/Home/your.png`)} alt=""  />
      <div className='w-r-293 text-center f-16 f-w-7 text-black'>
        Enjoy your AI companion time
      </div>
      <div className='flexcc w-r-135 h-r-30 br-28 f-12 my-10' style={{border: "0.54px solid #2F2F30"}}>Start chatting</div>
      <div className='w-r-345 h-r-163 flexsb' style={{border: "0.69px solid #000000"}}>
        <div className='h-100 w-r-70 p-2' style={{borderRight: "0.69px solid #000000"}}>
          <div className='f-9'>chat</div>
          <div className='h-r-9 w-100 br-5 flexc px-2 mb-6' style={{border: "0.35px solid #000000"}}>
            <SearchOutline fontSize={9} />
          </div>
          {
            chatList.map((item,index)=>{
              return  <div key={index}  className={`w-100 h-r-20   flexc  ${chat===item.chat ? 'bg-d9' : ''}`}   onClick={() => selChat(item.chat)} style={{borderBottom: "0.67px solid #000000"}}>
                <img className="w-r-18 h-r-18 mr-5" src={require(`@/assets/web/Home/chat${item.chat}.png`)} alt=""  />
                <div className='f-10 '>{item.name}</div>
              </div>
            })
          }
        </div>
        {
            chat!=0? <div className='h-100 w-r-133' style={{borderRight: "0.67px solid #000000"}}>
                  <img className="w-100 h-100" src={require('@/assets/mobile/Home/Lillian.png')} alt=""  />
            </div>:""
          }
        <div className='flex-fill h-100 p-10 flexd'>
            {
              chat!=0? <div className='flex-fill'>
                <div className='flexc'>
                  <img className="w-r-14 h-r-14 mr-4" src={require(`@/assets/web/Home/chat1.png`)} alt=""  />
                  <div className='msg f-10'>Good night, my friend😊</div>
                </div>
              </div>: <div className='flex-fill  flexcc f-10'>Select Chat to start the conversation</div>
            }
               
            <div className='h-r-16  w-100 flexcc'>
              <div className='h-r-14 w-r-109 my-10 br-5 overflow-hidden f-10 px-10' style={{border: "0.65px solid #000000"}}>Enter text</div>
              <img className="w-r-17 h-r-17 ml-10" src={require('@/assets/mobile/Home/mic.png')} alt=""  />
            </div>
          </div>
      </div>
    </div>



      {/* Features */}
    <div className='flexd align-items-center mt-40'>
      <div className='w-r-293 text-center f-16 f-w-7 text-black'>
      Features
      </div>
      <div className='w-100 flexsb  flex-wrap'>
        <img className="w-r-175 h-r-110"  src={require(`@/assets/mobile/Home/fe1.png`)} alt=""  />
        <img className="w-r-175 h-r-110" src={require(`@/assets/mobile/Home/fe2.png`)} alt=""  />
        <img className="w-r-175 h-r-110" src={require(`@/assets/mobile/Home/fe3.png`)} alt=""  />
        <img className="w-r-175 h-r-110" src={require(`@/assets/mobile/Home/fe4.png`)} alt=""  />
      </div>
    </div>

    {/* Roadmap */}
    <div className='flexd align-items-center mt-40'>
      <div className='w-r-293 text-center f-16 f-w-7 text-black'>
      Roadmap
      </div>
      <div className='w-100 flexcc'>
        <img className="w-r-340 h-r-341" src={require(`@/assets/mobile/Home/Roadmap.png`)} alt=""  />
      </div>
    </div>


        {/* Partners */}
    <div className='flexd align-items-center mt-40 footBg'>
      {/* <div className='w-r-293 text-center f-16 f-w-7 mb-20 text-black'>
      Partners
      </div>
      <div className='w-100 flexcc'>
        <img className="w-r-340 h-r-65" src={require(`@/assets/mobile/Home/Partners.png`)} alt=""  />
      </div> */}

      <div className='w-100 flexsb px-12'>
        <img className="w-r-98 h-r-51" src={require(`@/assets/mobile/logo.png`)} alt=""  />
        <div className='flexcc'>
         <img className="w-r-31 h-r-31" onClick={()=>{ Common.toTwitter();  }} src={require(`@/assets/mobile/Home/foot1.png`)} alt=""  />
         <img className="w-r-31 h-r-31 mx-8"    src={require(`@/assets/mobile/Home/foot2.png`)} alt=""  />
         <img className="w-r-31 h-r-31"   onClick={()=>{ Common.mirror();  }}  src={require(`@/assets/mobile/Home/foot3.png`)} alt=""  />
         <img className="w-r-31 h-r-31 mx-8"  onClick={()=>{ Common.deck();  }}   src={require(`@/assets/mobile/Home/foot4.png`)} alt=""  />
         <img className="w-r-31 h-r-31"  onClick={()=>{ Common.onepage();  }}  src={require(`@/assets/mobile/Home/foot5.png`)} alt=""  />
        </div>
      </div>
    </div>


  </div>
  return Common.isMobile ? Mobile : Web
}

